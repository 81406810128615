import { AssetClass, FactorTilt } from 'enums'
import { InvestmentTemplate, PartialInvestmentPreference } from 'types'
import {
  InvestmentTemplateFormPayload,
  InvestmentTemplateFormState,
} from 'types/InvestmentTemplateTypes'
import { FactorTiltObject } from 'types/generalTypes'

import {
  BALANCED,
  DEFAULT,
  DEFAULT_MULTI_ASSET_CLASS_INVESTMENT_PREFERENCE,
} from 'constants/portfolio.constants'
import { prune } from 'utils/generalUtils'

/**
 * @function formatToInvestmentTemplateForm
 * Maps an incoming InvestmentTemplate to match the shape of InvestmentTemplateFormState
 *
 * Current usecase is for adding or updating an Investment Template on the Investment Template page.
 * @returns InvestmentTemplateFormState
 */
export function formatToInvestmentTemplateForm(
  template: InvestmentTemplate
): InvestmentTemplateFormState {
  const { investmentPreferences } = template
  const factorTilts = investmentPreferences?.factorTilts

  const templateForm: InvestmentTemplateFormState = {
    benchmarkId: template.benchmarkId ?? null,
    displayName: template.displayName,

    /** Investment Preference Fields */
    assetClass: investmentPreferences?.assetClass ?? AssetClass.EQUITY,
    automation: investmentPreferences?.automation ?? null,
    buyListId: template.buyListId ?? null,
    dividendSlant: investmentPreferences?.dividendSlant ?? null,
    enableTaxOptimization: investmentPreferences?.enableTaxOptimization ?? null,
    excludedAssets: investmentPreferences?.excludedAssets ?? [],
    excludedIndustries: investmentPreferences?.excludedIndustries ?? [],
    excludedSectors: investmentPreferences?.excludedSectors ?? [],
    fractionalShares: investmentPreferences?.fractionalShares ?? null,
    multiAssetClassStrategy: investmentPreferences?.multiAssetClassStrategy,
    performancePriority: investmentPreferences?.performancePriority,
    riskTolerance: investmentPreferences?.riskTolerance ?? null,
    targetAssetCount: investmentPreferences?.targetAssetCount ?? null,
    targetCashWeight: investmentPreferences?.targetCashWeight
      ? investmentPreferences.targetCashWeight * 100
      : null,
    // Factor Tilts
    factorTilts: factorTilts ?? undefined,
    globalMacroSensitivity: getGlobalMacroSensitivity(factorTilts ?? []),
    interestRateSensitivity: getInterestRateSensitivity(factorTilts ?? []),
    investmentStyle: getInvestmentStyle(factorTilts ?? []),
    usDollarSensitivity: getUsDollarSensitivity(factorTilts ?? []),
    volatilityPreference: getVolatilityPreference(factorTilts ?? []),
  }

  return templateForm
}

/**
 * @function formatToInvestmentTemplatePayload
 * Formats the reducer form state into the payload shape
 */
export function formatToInvestmentTemplatePayload(
  formState: InvestmentTemplateFormState
): InvestmentTemplateFormPayload {
  const {
    assetClass,
    benchmarkId,
    buyListId,
    displayName,
    dividendSlant,
    factorTilts,
    fractionalShares,
    enableTaxOptimization,
    excludedAssets,
    excludedIndustries,
    excludedSectors,
    targetAssetCount,
    targetCashWeight,
    multiAssetClassStrategy,
    performancePriority,
    riskTolerance,
    automation,
  } = formState
  let investmentPreferences: PartialInvestmentPreference

  if (
    formState.assetClass === AssetClass.MULTI_ASSET_CLASS &&
    formState.multiAssetClassStrategy
  ) {
    investmentPreferences = {
      ...DEFAULT_MULTI_ASSET_CLASS_INVESTMENT_PREFERENCE,
      assetClass,
      automation,
      fractionalShares,
      multiAssetClassStrategy,
    }
  } else {
    investmentPreferences = {
      assetClass,
      automation,
      dividendSlant,
      factorTilts: factorTilts?.length ? factorTilts : null,
      fractionalShares,
      enableTaxOptimization,
      excludedAssets: excludedAssets.length ? excludedAssets : null,
      excludedIndustries: excludedIndustries.length ? excludedIndustries : null,
      excludedSectors: excludedSectors.length ? excludedSectors : null,
      targetAssetCount,
      targetCashWeight: targetCashWeight ? targetCashWeight / 100 : null,
      performancePriority,
      riskTolerance,
    }
  }

  return {
    benchmarkId,
    buyListId,
    displayName: displayName.trim(),
    investmentPreferences: prune(investmentPreferences),
  }
}

/**
 * PRIVATE
 */

function getFactorTiltType(
  factorTiltName: FactorTilt,
  defaultValue: string,
  factorTilts: FactorTiltObject[]
): string {
  const factorTilt = factorTilts?.find((tilt: FactorTiltObject) => {
    const tiltName = Object.keys(tilt)[0]
    return tiltName === factorTiltName
  })

  return factorTilt ? Object.values(factorTilt)[0] : defaultValue
}

function getGlobalMacroSensitivity(
  inputFactorTilts: FactorTiltObject[]
): string {
  return getFactorTiltType(
    FactorTilt.GLOBAL_MACRO_SENSITIVITY,
    DEFAULT,
    inputFactorTilts
  )
}

function getInterestRateSensitivity(
  inputFactorTilts: FactorTiltObject[]
): string {
  return getFactorTiltType(
    FactorTilt.INTEREST_RATE_SENSITIVITY,
    DEFAULT,
    inputFactorTilts
  )
}

function getInvestmentStyle(inputFactorTilts: FactorTiltObject[]): string {
  return getFactorTiltType(
    FactorTilt.INVESTMENT_STYLE,
    BALANCED,
    inputFactorTilts
  )
}

function getUsDollarSensitivity(inputFactorTilts: FactorTiltObject[]): string {
  return getFactorTiltType(
    FactorTilt.US_DOLLAR_SENSITIVITY,
    DEFAULT,
    inputFactorTilts
  )
}

function getVolatilityPreference(inputFactorTilts: FactorTiltObject[]): string {
  return getFactorTiltType(
    FactorTilt.VOLATILITY_PREFERENCE,
    BALANCED,
    inputFactorTilts
  )
}
