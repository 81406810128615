import { Button } from '@chakra-ui/react'
import { isBoolean } from 'lodash'
import { Dispatch } from 'react'

import { FactorTilt } from 'enums'
import { InvestmentTemplate, ReducerAction } from 'types'
import { InvestmentTemplateFormState } from 'types/InvestmentTemplateTypes'
import { PortfolioFormState } from 'types/portfolioTypes'

import { Box, Dropdown, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { clearFactorTilts } from 'components/reducers/InvestmentTemplateFormReducer'
import {
  setFactorTilts,
  setGlobalMacroSensitivity,
  setInterestRateSensitivity,
  setInvestmentStyle,
  setPerformancePriority,
  setUsDollarSensitivity,
  setVolatilityPreference,
} from 'components/reducers/portfolioFormReducer'
import { BALANCED_OPTION, DEFAULT_OPTION } from 'constants/general.constants'
import { portfolioFormOptions } from 'constants/portfolio.constants'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'
import {
  getDefaultPerformancePriority,
  isTemplateField,
} from 'utils/portfolioFormUtil'

import GlobalMacroSensitivityPopOver from '../Portfolio/PortfolioPreferences/GlobalMacroSensitivityPopOver'
import InterestRateSensitivityPopOver from '../Portfolio/PortfolioPreferences/InterestRateSensitivityPopOver'
import InvestmentStylePopOver from '../Portfolio/PortfolioPreferences/InvestmentStylePopOver'
import PerformancePriorityPopOver from '../Portfolio/PortfolioPreferences/PerformancePriorityPopOver'
import USDollarSensitivityPopOver from '../Portfolio/PortfolioPreferences/USDollarSensitivityPopOver'
import VolatilityPreferencePopOver from '../Portfolio/PortfolioPreferences/VolatilityPreferencePopOver'

type Props = {
  dispatch: Dispatch<ReducerAction>
  formState: PortfolioFormState | InvestmentTemplateFormState
  isClearable?: boolean
  selectedInvestmentTemplate?: InvestmentTemplate | null
  formErrors: {
    performancePriority?: string
    investmentStyle?: string
    volatilityPreference?: string
    interestRateSensitivity?: string
    globalMacroSensitivity?: string
    usDollarSensitivity?: string
  }
}

const PERFORMANCE_PRIORITY_STR = 'performancePriority'

export function FactorTiltsFormSection({
  dispatch,
  formErrors,
  formState,
  isClearable,
  selectedInvestmentTemplate = null,
}: Props) {
  const {
    enableTaxOptimization,
    factorTilts,
    globalMacroSensitivity,
    interestRateSensitivity,
    investmentStyle,
    performancePriority,
    usDollarSensitivity,
    volatilityPreference,
  } = formState
  const defaultPerformancePriority = getDefaultPerformancePriority(formState)
  const hasEnableTaxOptimization = isBoolean(enableTaxOptimization)

  // Click state logic for factor tilt fields managed below
  // if performance priority is not default set all factor tilt fields to false
  // if there is a factor tilt selection all other fields are set false except
  // for the selected field
  let clickState = {
    [PERFORMANCE_PRIORITY_STR]: true,
    [FactorTilt.GLOBAL_MACRO_SENSITIVITY]: true,
    [FactorTilt.INTEREST_RATE_SENSITIVITY]: true,
    [FactorTilt.INVESTMENT_STYLE]: true,
    [FactorTilt.VOLATILITY_PREFERENCE]: true,
    [FactorTilt.US_DOLLAR_SENSITIVITY]: true,
  }
  const hasFactorTiltSelection = Boolean(factorTilts && factorTilts.length > 0)
  if (performancePriority !== defaultPerformancePriority) {
    clickState[FactorTilt.GLOBAL_MACRO_SENSITIVITY] = false
    clickState[FactorTilt.INTEREST_RATE_SENSITIVITY] = false
    clickState[FactorTilt.INVESTMENT_STYLE] = false
    clickState[FactorTilt.US_DOLLAR_SENSITIVITY] = false
    clickState[FactorTilt.VOLATILITY_PREFERENCE] = false
  } else if (hasFactorTiltSelection) {
    clickState = {
      [PERFORMANCE_PRIORITY_STR]: false,
      [FactorTilt.GLOBAL_MACRO_SENSITIVITY]:
        globalMacroSensitivity !== DEFAULT_OPTION.value,
      [FactorTilt.INTEREST_RATE_SENSITIVITY]:
        interestRateSensitivity !== DEFAULT_OPTION.value,
      [FactorTilt.INVESTMENT_STYLE]: investmentStyle !== BALANCED_OPTION.value,
      [FactorTilt.VOLATILITY_PREFERENCE]:
        volatilityPreference !== BALANCED_OPTION.value,
      [FactorTilt.US_DOLLAR_SENSITIVITY]:
        usDollarSensitivity !== DEFAULT_OPTION.value,
    }
  }

  // This is for the investment preference template form
  if (isClearable && !hasEnableTaxOptimization) {
    clickState = {
      [PERFORMANCE_PRIORITY_STR]: true,
      [FactorTilt.GLOBAL_MACRO_SENSITIVITY]: false,
      [FactorTilt.INTEREST_RATE_SENSITIVITY]: false,
      [FactorTilt.INVESTMENT_STYLE]: false,
      [FactorTilt.VOLATILITY_PREFERENCE]: false,
      [FactorTilt.US_DOLLAR_SENSITIVITY]: false,
    }
  }

  const handlePerformancePriorityChange = (option: any) => {
    dispatch(setPerformancePriority(option.value))
  }

  const handleInvestmentStyleChange = (option: any) => {
    const tilts = []
    if (option.value !== BALANCED_OPTION.value) {
      tilts.push({ [FactorTilt.INVESTMENT_STYLE]: option.value })
    }
    dispatch(setInvestmentStyle(option.value))
    dispatch(setFactorTilts(tilts))
  }

  const handleVolatilityPreferenceChange = (option: any) => {
    const tilts = []
    if (option.value !== BALANCED_OPTION.value) {
      tilts.push({ [FactorTilt.VOLATILITY_PREFERENCE]: option.value })
    }
    dispatch(setVolatilityPreference(option.value))
    dispatch(setFactorTilts(tilts))
  }

  const handleInterestRateSensitivityChange = (option: any) => {
    const tilts = []
    if (option.value !== DEFAULT_OPTION.value) {
      tilts.push({ [FactorTilt.INTEREST_RATE_SENSITIVITY]: option.value })
    }
    dispatch(setInterestRateSensitivity(option.value))
    dispatch(setFactorTilts(tilts))
  }

  const handleGlobalMacroSensitivityChange = (option: any) => {
    const tilts = []
    if (option.value !== DEFAULT_OPTION.value) {
      tilts.push({ [FactorTilt.GLOBAL_MACRO_SENSITIVITY]: option.value })
    }
    dispatch(setGlobalMacroSensitivity(option.value))
    dispatch(setFactorTilts(tilts))
  }

  const handleUsDollarSensitivityChange = (option: any) => {
    const tilts = []
    if (option.value !== DEFAULT_OPTION.value) {
      tilts.push({ [FactorTilt.US_DOLLAR_SENSITIVITY]: option.value })
    }
    dispatch(setUsDollarSensitivity(option.value))
    dispatch(setFactorTilts(tilts))
  }

  const currentPerformancePriorityOptions = enableTaxOptimization
    ? portfolioFormOptions.performancePriorityOptions
    : portfolioFormOptions.performancePriorityOptionsWithTaxOptimizationOff

  return (
    <Box>
      <Box marginBottom={SPACE.sp24}>
        <FlexBox marginBottom={SPACE.sp8}>
          <Text
            color={COLOR.coolGray600}
            fontSize={FONT_SIZE.fs16}
            fontWeight={700}
          >
            Factor Tilts{' '}
            <Text
              color={COLOR.coolGray600}
              fontSize={FONT_SIZE.fs16}
              fontWeight={400}
            >
              (select one of the following)
            </Text>
          </Text>

          {isClearable && (factorTilts?.length || performancePriority) && (
            <Button
              color={COLOR.purple700}
              display="block"
              marginLeft={SPACE.sp16}
              onClick={(e: React.SyntheticEvent) => {
                e.preventDefault()
                dispatch(setPerformancePriority(null))
                dispatch(clearFactorTilts())
              }}
              size="xs"
              variant="secondary"
            >
              Clear Factor Tilts
            </Button>
          )}
        </FlexBox>
      </Box>

      <FlexBox gap={SPACE.sp12} marginBottom={SPACE.sp12}>
        <Box flex={1}>
          <Dropdown
            isDisabled={
              isTemplateField(
                selectedInvestmentTemplate,
                'performancePriority'
              ) || !clickState.performancePriority
            }
            isSearchable={false}
            label="Performance Priority"
            placeholder="Select an option"
            options={currentPerformancePriorityOptions}
            onChange={handlePerformancePriorityChange}
            value={currentPerformancePriorityOptions.find(
              ({ value }) => value === performancePriority
            )}
            errorMessage={formErrors?.performancePriority}
            popOver={<PerformancePriorityPopOver />}
            variant={
              isTemplateField(selectedInvestmentTemplate, 'performancePriority')
                ? 'template'
                : 'base'
            }
          />
        </Box>
        <Box flex={1}>
          <Dropdown
            isDisabled={
              isTemplateField(selectedInvestmentTemplate, 'factorTilts') ||
              !clickState.investmentStyle
            }
            isSearchable={false}
            label="Investment Style"
            placeholder="Select an option"
            options={portfolioFormOptions.investmentStyleOptions}
            onChange={handleInvestmentStyleChange}
            value={portfolioFormOptions.investmentStyleOptions.find(
              ({ value }) => value === investmentStyle
            )}
            errorMessage={formErrors?.investmentStyle}
            popOver={<InvestmentStylePopOver />}
            variant={
              isTemplateField(selectedInvestmentTemplate, 'factorTilts') ||
              (isTemplateField(
                selectedInvestmentTemplate,
                'performancePriority'
              ) &&
                performancePriority !== defaultPerformancePriority)
                ? 'template'
                : 'base'
            }
          />
        </Box>
        <Box flex={1}>
          <Dropdown
            isDisabled={
              isTemplateField(selectedInvestmentTemplate, 'factorTilts') ||
              !clickState.volatilityPreference
            }
            isSearchable={false}
            label="Volatility Preference"
            placeholder="Select an option"
            options={portfolioFormOptions.volatilityPreferenceOptions}
            onChange={handleVolatilityPreferenceChange}
            value={portfolioFormOptions.volatilityPreferenceOptions.find(
              ({ value }) => value === volatilityPreference
            )}
            errorMessage={formErrors?.volatilityPreference}
            popOver={<VolatilityPreferencePopOver />}
            variant={
              isTemplateField(selectedInvestmentTemplate, 'factorTilts') ||
              (isTemplateField(
                selectedInvestmentTemplate,
                'performancePriority'
              ) &&
                performancePriority !== defaultPerformancePriority)
                ? 'template'
                : 'base'
            }
          />
        </Box>
      </FlexBox>
      <FlexBox gap={SPACE.sp12} marginBottom={SPACE.sp12}>
        <Box flex={1}>
          <Dropdown
            isDisabled={
              isTemplateField(selectedInvestmentTemplate, 'factorTilts') ||
              !clickState.interestRateSensitivity
            }
            isSearchable={false}
            label="Interest Rate Sensitivity"
            placeholder="Select an option"
            options={portfolioFormOptions.sensitivityOptions}
            onChange={handleInterestRateSensitivityChange}
            value={portfolioFormOptions.sensitivityOptions.find(
              ({ value }) => value === interestRateSensitivity
            )}
            errorMessage={formErrors?.interestRateSensitivity}
            popOver={<InterestRateSensitivityPopOver />}
            variant={
              isTemplateField(
                selectedInvestmentTemplate,
                'performancePriority'
              ) && performancePriority !== defaultPerformancePriority
                ? 'template'
                : 'base'
            }
          />
        </Box>
        <Box flex={1}>
          <Dropdown
            isDisabled={
              isTemplateField(selectedInvestmentTemplate, 'factorTilts') ||
              !clickState.globalMacroSensitivity
            }
            isSearchable={false}
            label="Global Macro Sensitivity"
            placeholder="Select an option"
            options={portfolioFormOptions.sensitivityOptions}
            onChange={handleGlobalMacroSensitivityChange}
            value={portfolioFormOptions.sensitivityOptions.find(
              ({ value }) => value === globalMacroSensitivity
            )}
            errorMessage={formErrors?.globalMacroSensitivity}
            popOver={<GlobalMacroSensitivityPopOver />}
            variant={
              isTemplateField(
                selectedInvestmentTemplate,
                'performancePriority'
              ) && performancePriority !== defaultPerformancePriority
                ? 'template'
                : 'base'
            }
          />
        </Box>
        <Box flex={1}>
          <Dropdown
            isDisabled={
              isTemplateField(selectedInvestmentTemplate, 'factorTilts') ||
              !clickState.usDollarSensitivity
            }
            isSearchable={false}
            label="US Dollar Sensitivity"
            placeholder="Select an option"
            options={portfolioFormOptions.sensitivityOptions}
            onChange={handleUsDollarSensitivityChange}
            value={portfolioFormOptions.sensitivityOptions.find(
              ({ value }) => value === usDollarSensitivity
            )}
            menuPlacement="auto"
            errorMessage={formErrors?.usDollarSensitivity}
            popOver={<USDollarSensitivityPopOver />}
            variant={
              isTemplateField(
                selectedInvestmentTemplate,
                'performancePriority'
              ) && performancePriority !== defaultPerformancePriority
                ? 'template'
                : 'base'
            }
          />
        </Box>
      </FlexBox>
    </Box>
  )
}
