import { useState } from 'react'

import { paveApi } from 'api'
import { Box, Tag, Text } from 'components/common'
import BreadcrumbBar from 'components/common/BreadcrumbBar/BreadcrumbBar'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { PageLayout } from 'components/partials'
import BuyListCard from 'components/partials/BuyLists/BuyListCard'
import CreateBuyListSlideIn from 'components/partials/CreateBuyListSlideIn'
import SelectedBuyListSlideIn from 'components/partials/SelectedBuyListSlideIn'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

function BuyListPage() {
  const [isCreateBuyListOpen, setIsCreateBuyListOpen] = useState(false)
  const [isSelectedBuyListOpen, setIsSelectedBuyListOpen] = useState(false)
  const [selectedBuyListId, setSelectedBuyListId] = useState<string | null>(
    null
  )
  const breadcrumbs = [
    {
      name: 'Home Page',
      route: `/`,
    },
    {
      name: 'Approved Buy Lists',
      route: `#`,
    },
  ]

  const handleCreateBuyList = () => {
    setIsCreateBuyListOpen(true)
  }

  const hideCreateBuyListSlideIn = () => setIsCreateBuyListOpen(false)

  const handleBuyListClick = (selectedBuyListId: string) => {
    setSelectedBuyListId(selectedBuyListId)
    setIsSelectedBuyListOpen(true)
  }

  const hideSelectedBuyList = () => {
    setIsSelectedBuyListOpen(false)
    setSelectedBuyListId(null)
  }

  // Hardcoded constants for now, will be updated when endpoints are made

  const { data } = paveApi.useGetBuyListsQuery()
  const isBuyListOwner = data?.isBuyListOwner
  const ownerEmail = data?.ownerEmail
  const buyLists = isBuyListOwner ? data?.buyListsFull : data?.buyListsBasic

  return (
    <PageLayout>
      <BreadcrumbBar paths={breadcrumbs} />
      <Box marginLeft={SPACE.sp32}>
        <Text
          color={COLOR.coolGray600}
          fontWeight="700"
          fontSize={FONT_SIZE.fs14}
          lineHeight="sm"
          as="h1"
        >
          Approved Buy Lists
        </Text>
        <FlexBox justifyContent="space-between">
          <Text
            color={COLOR.coolGray600}
            fontWeight="700"
            fontSize={FONT_SIZE.fs28}
            lineHeight="3xl"
            as="h2"
          >
            {isBuyListOwner ? 'Create & Manage Buy Lists' : 'View Buy Lists'}
          </Text>
          {!isBuyListOwner && ownerEmail && (
            <FlexBox paddingRight={SPACE.sp28} alignItems="center">
              <Text
                color={COLOR.coolGray600}
                fontSize={FONT_SIZE.fs12}
                fontWeight="700"
                lineHeight="3xl"
              >
                Firm Buy List Owner:
              </Text>
              <Tag variant="green">{ownerEmail}</Tag>
            </FlexBox>
          )}
        </FlexBox>
      </Box>
      <FlexBox
        flexDirection="row"
        gap={SPACE.sp24}
        padding={`${SPACE.sp20} ${SPACE.sp32}`}
      >
        <BuyListCard
          buyLists={buyLists || []}
          isBuyListOwner={!!isBuyListOwner}
          handleCreateBuyList={handleCreateBuyList}
          handleBuyListClick={handleBuyListClick}
        />
      </FlexBox>
      <CreateBuyListSlideIn
        showModal={isCreateBuyListOpen}
        closeModal={hideCreateBuyListSlideIn}
      />
      {selectedBuyListId && (
        <SelectedBuyListSlideIn
          selectedBuyListId={selectedBuyListId}
          isBuyListOwner={!!isBuyListOwner}
          showModal={isSelectedBuyListOpen}
          closeModal={hideSelectedBuyList}
        />
      )}
    </PageLayout>
  )
}

export default BuyListPage
