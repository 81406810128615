import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/solid'
import { isFinite } from 'lodash'
import { useNavigate } from 'react-router-dom'

import { Asset } from 'types'

import { Box, Tag, Text } from 'components/common'
import InteractiveCard from 'components/common/Card/InteractiveCard'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'
import { formatAsCurrency } from 'utils'
import { roundToHundredths } from 'utils/numberUtils'

type Props = {
  asset: Asset
  portfolioId: string
  isSupportedButNotByAssetClass: boolean
}

function AssetListItem({
  asset,
  portfolioId,
  isSupportedButNotByAssetClass,
}: Props) {
  const navigate = useNavigate()
  const onAssetClick = (e: React.SyntheticEvent) => {
    e.preventDefault()
    navigate(`/asset/${asset.symbol}?portfolioId=${portfolioId}`)
  }

  const dayGainValue = (asset.priceDetails?.dayGain * asset.totalValue) / 100

  return (
    <a
      onClick={onAssetClick}
      href={`/asset/${asset.symbol}?portfolioId=${portfolioId}`}
    >
      <InteractiveCard
        display="flex"
        alignItems="center"
        fontSize={FONT_SIZE.fs10}
        marginBottom={SPACE.sp16}
        style={{ cursor: 'pointer' }}
        gap={SPACE.sp4}
      >
        <Box flex={1}>
          <Tag>{asset.symbol}</Tag>
        </Box>
        <FlexBox
          flex={2}
          justifyContent="flex-start"
          alignItems="center"
          overflow="hidden"
          gap={SPACE.sp8}
        >
          {!asset.isSupported && (
            <Box
              width={SPACE.sp8}
              height={SPACE.sp8}
              borderRadius="100%"
              bg="red.500"
              flex={1}
            />
          )}
          {isSupportedButNotByAssetClass && (
            <Box
              width={SPACE.sp8}
              height={SPACE.sp8}
              borderRadius="100%"
              bg="yellow.500"
              flex={1}
            />
          )}
          <Text
            className="interactive-text"
            fontSize={FONT_SIZE.fs12}
            fontWeight="medium"
            lineHeight="xs"
            wrapText={true}
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            title={asset.name}
            flex={20}
          >
            {asset.name}
          </Text>
        </FlexBox>

        <Box flex={1}>
          <Text
            color={COLOR.coolGray700}
            fontSize={FONT_SIZE.fs12}
            fontWeight="medium"
          >
            {isFinite(asset.price) ? formatAsCurrency(asset.price) : '$--'}
          </Text>
        </Box>
        <Box flex={1}>
          <Text
            color={COLOR.coolGray700}
            fontSize={FONT_SIZE.fs12}
            fontWeight="medium"
          >
            {roundToHundredths(asset.shares)}
          </Text>
        </Box>
        <Box flex={1}>
          <Text
            color={COLOR.coolGray700}
            fontSize={FONT_SIZE.fs12}
            fontWeight="medium"
          >
            {isFinite(asset.totalValue)
              ? formatAsCurrency(asset.totalValue)
              : 'Pricing unavailable'}
          </Text>
        </Box>
        <Box flex={1}>
          <Text
            color={COLOR.coolGray700}
            fontSize={FONT_SIZE.fs12}
            fontWeight="medium"
          >
            {isFinite(asset.percentageOfPortfolio) &&
              `${asset.percentageOfPortfolio}%`}
          </Text>
        </Box>
        {isFinite(asset.priceDetails?.dayGain) ? (
          <>
            <Box flex={1}>
              <Tag
                size="sm"
                icon={
                  asset.priceDetails.dayGain >= 0 ? (
                    <ArrowUpIcon />
                  ) : (
                    <ArrowDownIcon />
                  )
                }
                iconPosition="left"
                variant={asset.priceDetails.dayGain >= 0 ? 'green' : 'red'}
              >
                <Text fontSize="xxs" fontWeight="bold">
                  {asset.priceDetails.dayGain}%
                </Text>
              </Tag>
            </Box>
            <Box flex={1}>
              <Text
                color={COLOR.coolGray700}
                fontSize={FONT_SIZE.fs12}
                fontWeight="medium"
              >
                {formatAsCurrency(dayGainValue)}
              </Text>
            </Box>
          </>
        ) : (
          <>
            <Box flex={1} />
            <Box flex={1} />
          </>
        )}
      </InteractiveCard>
    </a>
  )
}

export default AssetListItem
