import styled from '@emotion/styled'
import { round } from 'lodash'
import { useEffect, useState } from 'react'

import { BenchmarkType } from 'enums'
import { ASSET_SUPPORTED_TYPE, Asset, Benchmark } from 'types'

import { paveApi } from 'api'
import { Box, Dropdown, Text } from 'components/common'
import { AssetStatisticsInformation, StatItem } from 'components/partials'
import { DropdownOptionString } from 'components/partials/DropdownWithTags'
import ModalLoader from 'components/partials/ModalLoader'

type AssetStats = {
  returnPotential: number
  relativeRisk: number
}
type Props = {
  asset: Asset
  initialBenchmark?: Benchmark
}

function AssetStatistics({ asset, initialBenchmark }: Props) {
  const { data: benchmarks, isLoading: areBenchmarksLoading } =
    paveApi.useGetBenchmarksQuery()

  const [stats, setStats] = useState<AssetStats>({
    returnPotential: 0,
    relativeRisk: 0,
  })
  const [currentBenchmark, setCurrentBenchmark] = useState<
    Benchmark | undefined
  >(initialBenchmark)

  /**
   * Initial benchmark is undefined when we
   * access the asset overview page without a portfolio
   */
  if (!currentBenchmark && benchmarks) {
    setCurrentBenchmark(benchmarks[0])
  }

  const filteredBenchmarks = benchmarks?.filter((benchmark) => {
    const bothTypeStock =
      asset.supportedType === ASSET_SUPPORTED_TYPE.STOCK &&
      benchmark.type === BenchmarkType.STOCK
    return bothTypeStock || asset.supportedType === ASSET_SUPPORTED_TYPE.ALL
  })

  const values = filteredBenchmarks?.map((benchmark) => ({
    value: benchmark.id,
    label: benchmark.name,
  })) as DropdownOptionString[]

  const selectedBenchmark = benchmarks?.find(
    (benchmark) => benchmark === currentBenchmark
  )

  const handleBenchmarkChange = (option: DropdownOptionString) => {
    if (option.value !== currentBenchmark?.id) {
      const newBenchmark = benchmarks?.find(
        (benchmark) => option.value === benchmark.id
      )
      setCurrentBenchmark(newBenchmark)
    }
  }

  useEffect(() => {
    const {
      returnPercentile: assetReturnPercentile,
      riskPercentile: assetRiskPercentile,
    } = asset
    const {
      returnPercentile: benchmarkReturnPercentile,
      riskPercentile: benchmarkRiskPercentile,
    } = currentBenchmark || {}

    if (
      assetReturnPercentile &&
      assetRiskPercentile &&
      benchmarkReturnPercentile &&
      benchmarkRiskPercentile
    ) {
      const returnPotential = round(
        assetReturnPercentile - benchmarkReturnPercentile,
        1
      )
      const relativeRisk = round(
        assetRiskPercentile - benchmarkRiskPercentile,
        1
      )

      setStats({
        returnPotential: returnPotential,
        relativeRisk: relativeRisk,
      })
    }
  }, [asset, currentBenchmark])

  return (
    <Container>
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap="12px"
      >
        <Box display="flex">
          <Text
            fontWeight="bold"
            fontSize="xl"
            lineHeight="xl"
            color="coolGray.600"
            as="p"
          >
            Statistics vs.
          </Text>
        </Box>
        <Box display="flex">
          <StyledDropdown
            value={
              values && selectedBenchmark
                ? [
                    {
                      value: selectedBenchmark?.id,
                      label: selectedBenchmark?.name,
                    },
                  ]
                : [
                    {
                      value: initialBenchmark?.id,
                      label: initialBenchmark?.name,
                    },
                  ]
            }
            options={values}
            onChange={(option: any) => handleBenchmarkChange(option)}
          />
        </Box>
        <Box display="flex" flex={1} justifyContent="flex-end">
          <AssetStatisticsInformation />
        </Box>
      </Box>
      <Box
        position="relative"
        display="flex"
        flexDirection="row"
        alignItems="flex-start"
        padding="0px"
        gap="24px"
        width="100%"
      >
        {!stats || areBenchmarksLoading ? (
          <ModalLoader />
        ) : (
          <>
            <StatItem value={stats.returnPotential} label="Return Potential" />
            <StatItem
              value={stats.relativeRisk}
              label="Relative Risk"
              reversed
            />
          </>
        )}
      </Box>
    </Container>
  )
}
const StyledDropdown = styled(Dropdown)`
  .select__control {
    min-height: 0px;
    border-color: ${({ theme }) => theme.colors.coolGray[300]};
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
`
export default AssetStatistics
