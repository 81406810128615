import { AssetClass } from 'enums'
import { PerformancePriority } from 'enums/PerformancePriorityEnum'
import { Asset, InvestmentTemplate, ReducerAction, Strategies } from 'types'
import { FactorTiltObject } from 'types/generalTypes'
import { PortfolioFormState } from 'types/portfolioTypes'

import { DEFAULT_FORM_STATE } from 'constants/portfolio.constants'
import {
  getDefaultPerformancePriority,
  updatePortfolioFormGivenInvestmentTemplate,
} from 'utils/portfolioFormUtil'

/**
 * ACTION CONSTANTS
 */
const CLEAR_STRATEGY = 'CLEAR_STRATEGY'
const EVALUATE_IMPACTED_TAX_OPTIMIZATION_FIELDS =
  'EVALUATE_IMPACTED_TAX_OPTIMIZATION_FIELDS'
const EVALUATE_TEMPLATE_PREFERENCES = 'EVALUATE_TEMPLATE_PREFERENCES'
const DEFAULT_STATE = 'DEFAULT_STATE'
const SET_ACTIVE_CONNECTED_ACCOUNT_IDS = 'SET_ACTIVE_CONNECTED_ACCOUNT_IDS'
const SET_ASSETS = 'SET_ASSETS'
const SET_ASSET_CLASS = 'SET_ASSET_CLASS'
const SET_AUTOMATION = 'SET_AUTOMATION'
const SET_AVAILABLE_CASH = 'SET_AVAILABLE_CASH'
const SET_BENCHMARK_ID = 'SET_BENCHMARK_ID'
const SET_BUY_LIST_ID = 'SET_BUY_LIST_ID'
const SET_CLIENT_ACCOUNT_ID = 'SET_CLIENT_ACCOUNT_ID'
const SET_DIVIDEND_SLANT = 'SET_DIVIDEND_SLANT'
const SET_ENABLE_TAX_OPTIMIZATION = 'SET_ENABLE_TAX_OPTIMIZATION'
const SET_EXCLUDED_ASSETS = 'SET_EXCLUDED_ASSETS'
const SET_EXCLUDED_INDUSTRIES = 'SET_EXCLUDED_INDUSTRIES'
const SET_EXCLUDED_SECTORS = 'SET_EXCLUDED_SECTORS'
const SET_DISPLAY_NAME = 'SET_DISPLAY_NAME'
const SET_FRACTIONAL_SHARES = 'SET_FRACTIONAL_SHARES'
const SET_INITIAL_PORTFOLIO_FORM_STATE = 'SET_INITIAL_PORTFOLIO_FORM_STATE'
const SET_INVESTMENT_TEMPLATE_ID = 'SET_INVESTMENT_TEMPLATE_ID'
const SET_IS_THIRD_PARTY_MANAGED = 'SET_IS_THIRD_PARTY_MANAGED'
const SET_PERFORMANCE_PRIORITY = 'SET_PERFORMANCE_PRIORITY'
const SET_RISK_TOLERANCE = 'SET_RISK_TOLERANCE'
const SET_DEFAULT_TARGET_ASSET_COUNT = 'SET_DEFAULT_TARGET_ASSET_COUNT'
const SET_DEFAULT_TARGET_CASH_WEIGHT = 'SET_DEFAULT_TARGET_CASH_WEIGHT'
const SET_STRATEGY = 'SET_STRATEGY'
const SET_USER_APPROVED_AUTOMATED_TRADES_ONCE =
  'SET_USER_APPROVED_AUTOMATED_TRADES_ONCE'
/** Factor Tilts */
const SET_FACTOR_TILTS = 'SET_FACTOR_TILTS'
const SET_GLOBAL_MACRO_SENSITIVITY = 'SET_GLOBAL_MACRO_SENSITIVITY'
const SET_INTEREST_RATE_SENSITIVITY = 'SET_INTEREST_RATE_SENSITIVITY'
const SET_INVESTMENT_STYLE = 'SET_INVESTMENT_STYLE'
const SET_US_DOLLAR_SENSITIVITY = 'SET_US_DOLLAR_SENSITIVITY'
const SET_VOLATILITY_PREFERENCE = 'SET_VOLATILITY_PREFERENCE'

/**
 * REDUCER
 */
export function portfolioFormReducer(
  oldState: PortfolioFormState,
  action: ReducerAction
): PortfolioFormState {
  let state: PortfolioFormState = JSON.parse(JSON.stringify(oldState))
  switch (action.type) {
    case CLEAR_STRATEGY:
      state.multiAssetClassStrategy = undefined
      break
    case DEFAULT_STATE:
      // Use in case a state refresh is needed
      const defaultState = JSON.parse(JSON.stringify(DEFAULT_FORM_STATE))
      state = defaultState
      break
    case EVALUATE_IMPACTED_TAX_OPTIMIZATION_FIELDS:
      // Performance priority is set to default only if there is a factor tilt
      // selection or the current performance priority is selected on default
      //
      // This case should only be hit after updating tax optimization
      const defaultPerformancePriority = getDefaultPerformancePriority(state)
      const otherDefaultPriority =
        defaultPerformancePriority === PerformancePriority.SLIGHTLY_O
          ? PerformancePriority.BALANCED
          : PerformancePriority.SLIGHTLY_O
      const hasFactorTilts = Boolean(state.factorTilts?.length)

      if (
        hasFactorTilts ||
        state.performancePriority === otherDefaultPriority
      ) {
        state.performancePriority = defaultPerformancePriority
      }
      break
    case EVALUATE_TEMPLATE_PREFERENCES:
      const newFormState = updatePortfolioFormGivenInvestmentTemplate(
        state,
        action.payload
      )
      state = newFormState
      break
    case SET_ACTIVE_CONNECTED_ACCOUNT_IDS:
      state.activeConnectedAccountIds = action.payload
      break
    case SET_ASSETS:
      state.assets = [...action.payload]
      break
    case SET_ASSET_CLASS:
      // TODO: May need to reassign targetAssetCount if assigned to multi asset class
      state.assetClass = action.payload
      break
    case SET_AUTOMATION:
      state.automation = action.payload
      break
    case SET_AVAILABLE_CASH:
      state.availableCash = action.payload
      break
    case SET_BENCHMARK_ID:
      state.benchmarkId = action.payload
      break
    case SET_BUY_LIST_ID:
      state.buyListId = action.payload
      break
    case SET_CLIENT_ACCOUNT_ID:
      state.clientAccountId = action.payload
      break
    case SET_DISPLAY_NAME:
      state.displayName = action.payload
      break
    case SET_DIVIDEND_SLANT:
      state.dividendSlant = action.payload
      break
    case SET_ENABLE_TAX_OPTIMIZATION:
      state.enableTaxOptimization = action.payload
      break
    case SET_EXCLUDED_ASSETS:
      state.excludedAssets = action.payload
      break
    case SET_EXCLUDED_INDUSTRIES:
      state.excludedIndustries = action.payload
      break
    case SET_EXCLUDED_SECTORS:
      state.excludedSectors = action.payload
      break
    case SET_FRACTIONAL_SHARES:
      state.fractionalShares = action.payload
      break
    case SET_INITIAL_PORTFOLIO_FORM_STATE:
      const initialState = JSON.parse(JSON.stringify(action.payload))
      state = initialState
      break
    case SET_INVESTMENT_TEMPLATE_ID:
      state.investmentTemplateId = action.payload
      break
    case SET_IS_THIRD_PARTY_MANAGED:
      state.isThirdPartyManaged = action.payload
      break
    case SET_PERFORMANCE_PRIORITY:
      state.performancePriority = action.payload
      break
    case SET_RISK_TOLERANCE:
      state.riskTolerance = action.payload
      break
    case SET_STRATEGY:
      state.multiAssetClassStrategy = action.payload
      break
    case SET_DEFAULT_TARGET_ASSET_COUNT:
      state.targetAssetCount = action.payload
      break
    case SET_DEFAULT_TARGET_CASH_WEIGHT:
      state.targetCashWeight = action.payload
      break
    case SET_USER_APPROVED_AUTOMATED_TRADES_ONCE:
      state.userApprovedAutomatedTradesOnce = action.payload
      break
    // Factor Tilts
    case SET_FACTOR_TILTS:
      state.factorTilts = action.payload
      break
    case SET_GLOBAL_MACRO_SENSITIVITY:
      state.globalMacroSensitivity = action.payload
      break
    case SET_INTEREST_RATE_SENSITIVITY:
      state.interestRateSensitivity = action.payload
      break
    case SET_INVESTMENT_STYLE:
      state.investmentStyle = action.payload
      break
    case SET_US_DOLLAR_SENSITIVITY:
      state.usDollarSensitivity = action.payload
      break
    case SET_VOLATILITY_PREFERENCE:
      state.volatilityPreference = action.payload
      break
    default:
      break
  }

  return state
}

/**
 * ACTIONS
 */
export const clearStrategy = (): ReducerAction => {
  return { type: CLEAR_STRATEGY }
}
export const evaluateImpactedTaxOptimizationFields = (): ReducerAction => {
  return { type: EVALUATE_IMPACTED_TAX_OPTIMIZATION_FIELDS }
}
export const setActiveConnectedAccountIds = (
  allAccounts: string[]
): ReducerAction => {
  return { type: SET_ACTIVE_CONNECTED_ACCOUNT_IDS, payload: allAccounts }
}
export const setAssets = (assets: Asset[]): ReducerAction => {
  return { type: SET_ASSETS, payload: assets }
}
export const setAssetClass = (assetClass: AssetClass): ReducerAction => {
  return { type: SET_ASSET_CLASS, payload: assetClass }
}
export const setAutomation = (automation: boolean | null): ReducerAction => {
  return { type: SET_AUTOMATION, payload: automation }
}
export const setAvailableCash = (availableCash: number): ReducerAction => {
  return { type: SET_AVAILABLE_CASH, payload: availableCash }
}
export const setBenchmarkId = (benchmarkId: string | null): ReducerAction => {
  return {
    type: SET_BENCHMARK_ID,
    payload: benchmarkId,
  }
}
export const setBuyListId = (buyListId: string | null): ReducerAction => {
  return {
    type: SET_BUY_LIST_ID,
    payload: buyListId,
  }
}
export const setClientAccountId = (clientAccountId: string): ReducerAction => {
  return { type: SET_CLIENT_ACCOUNT_ID, payload: clientAccountId }
}
export const setDefaultState = (): ReducerAction => {
  return {
    type: DEFAULT_STATE,
  }
}
export const setDisplayName = (name: string): ReducerAction => {
  return {
    type: SET_DISPLAY_NAME,
    payload: name,
  }
}
export const setDividendSlant = (
  dividendSlant: string | null
): ReducerAction => {
  return {
    type: SET_DIVIDEND_SLANT,
    payload: dividendSlant,
  }
}
export const setEnableTaxOptimization = (isEnabled: boolean): ReducerAction => {
  return { type: SET_ENABLE_TAX_OPTIMIZATION, payload: isEnabled }
}
export const setExcludedAssets = (excludedAssets: string[]): ReducerAction => {
  return { type: SET_EXCLUDED_ASSETS, payload: excludedAssets }
}
export const setExcludedIndustries = (
  excludedIndustries: string[]
): ReducerAction => {
  return { type: SET_EXCLUDED_INDUSTRIES, payload: excludedIndustries }
}
export const setExcludedSectors = (
  excludedSectors: string[]
): ReducerAction => {
  return { type: SET_EXCLUDED_SECTORS, payload: excludedSectors }
}
export const setFractionalShares = (
  isFractionalSharesEnabled: boolean
): ReducerAction => {
  return { type: SET_FRACTIONAL_SHARES, payload: isFractionalSharesEnabled }
}
export const setInitialPortfolioForm = (
  portfolio: PortfolioFormState
): ReducerAction => {
  return {
    type: SET_INITIAL_PORTFOLIO_FORM_STATE,
    payload: portfolio,
  }
}
export const setInvestmentTemplateId = (
  investmentTemplateId: string | null
): ReducerAction => {
  return { type: SET_INVESTMENT_TEMPLATE_ID, payload: investmentTemplateId }
}
export const setIsThirdPartyManaged = (isThirdPartyManaged: boolean) => {
  return {
    type: SET_IS_THIRD_PARTY_MANAGED,
    payload: isThirdPartyManaged,
  }
}
export const setPerformancePriority = (
  priorityNumber: PerformancePriority | null
) => {
  return {
    type: SET_PERFORMANCE_PRIORITY,
    payload: priorityNumber,
  }
}
export const setRiskTolerance = (riskTolerance: string | null) => {
  return {
    type: SET_RISK_TOLERANCE,
    payload: riskTolerance,
  }
}
export const setStrategy = (strategy?: Strategies | null): ReducerAction => {
  return {
    type: SET_STRATEGY,
    payload: strategy,
  }
}
export const setTargetAssetCount = (count: number): ReducerAction => {
  return {
    type: SET_DEFAULT_TARGET_ASSET_COUNT,
    payload: count,
  }
}
export const setTargetCashWeight = (count: number): ReducerAction => {
  return {
    type: SET_DEFAULT_TARGET_CASH_WEIGHT,
    payload: count,
  }
}
export const setUserApprovedAutomatedTradesOnce = (
  isApprovedOnce: boolean
): ReducerAction => {
  return {
    type: SET_USER_APPROVED_AUTOMATED_TRADES_ONCE,
    payload: isApprovedOnce,
  }
}

export const evaluateTemplatePreferences = (
  investmentTemplate: InvestmentTemplate
): ReducerAction => {
  return {
    type: EVALUATE_TEMPLATE_PREFERENCES,
    payload: investmentTemplate,
  }
}
// Factor Tilts
export const setFactorTilts = (
  factorTilts?: FactorTiltObject[]
): ReducerAction => {
  return {
    type: SET_FACTOR_TILTS,
    payload: factorTilts,
  }
}
export const setGlobalMacroSensitivity = (
  globalMacroSensitivity: string
): ReducerAction => {
  return {
    type: SET_GLOBAL_MACRO_SENSITIVITY,
    payload: globalMacroSensitivity,
  }
}
export const setInterestRateSensitivity = (
  interestRateSensitivity: string
): ReducerAction => {
  return {
    type: SET_INTEREST_RATE_SENSITIVITY,
    payload: interestRateSensitivity,
  }
}
export const setInvestmentStyle = (investmentStyle: string): ReducerAction => {
  return {
    type: SET_INVESTMENT_STYLE,
    payload: investmentStyle,
  }
}
export const setUsDollarSensitivity = (
  usDollarSensitivity: string
): ReducerAction => {
  return {
    type: SET_US_DOLLAR_SENSITIVITY,
    payload: usDollarSensitivity,
  }
}
export const setVolatilityPreference = (
  volatilityPreference: string
): ReducerAction => {
  return {
    type: SET_VOLATILITY_PREFERENCE,
    payload: volatilityPreference,
  }
}
