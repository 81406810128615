/**
 * RiskTolerance Enum
 */
export const RiskTolerance = {
  AGGRESSIVE: 'aggressive',
  MODERATE: 'moderate',
  SUGGESTION: 'suggestion',
} as const

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RiskTolerance = typeof RiskTolerance[keyof typeof RiskTolerance]
