import { UploadOutlined } from '@ant-design/icons'
import { isEmpty, size } from 'lodash'
import { useMemo, useState } from 'react'

import { BuyList, BuyListAsset, STATUS_ENUM } from 'types'
import { BuyListFormPayload } from 'types/buyListTypes.js'
import { UpdateBuyListAssetsWithFileResponseDto } from 'types/dto/BuyList.dto'
import { ErrorObject } from 'types/generalTypes'

import { paveApi } from 'api'
import { Box, Button, Text, TextInput } from 'components/common'
import Card from 'components/common/Card/Card'
import FlexBox from 'components/common/FlexBox/FlexBox'
import ModalLoader from 'components/partials/ModalLoader'
import { DISPLAY_NAME_LENGTH_LIMIT } from 'constants/general.constants'
import {
  BUY_LIST_REQUIRES_ONE_ASSET,
  REQUIRED_FIELD,
} from 'constants/validation.constants'
import { useCustomToast } from 'hooks/useCustomToast'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

import BuyListAssetsTable from '../BuyListAssetsTable'
import BuyListLearnMoreModal from '../Modals/BuyListLearnMoreModal.tsx'
import BuyListAssetPopOver from '../PopOver/BuyListAssetPopOver'
import UploadBuyListAssetsModal from '../UploadBuyListAssetsModal'

type Props = {
  initialBuyList: BuyList | null
  showLoader?: boolean
  isNewBuyList: boolean
  submitBuyListForm: (buyListPayload: BuyListFormPayload) => void
}
export default function BuyListForm({
  initialBuyList,
  showLoader,
  isNewBuyList,
  submitBuyListForm,
}: Props) {
  const { showErrorToast, showSuccessToast } = useCustomToast()
  const [displayName, setDisplayName] = useState<string>(
    initialBuyList?.displayName ?? ''
  )
  const [assets, setAssets] = useState<BuyListAsset[]>(
    initialBuyList?.assets ?? []
  )

  const [formErrors, setFormErrors] = useState<ErrorObject>({})

  const [isLearnMoreModalOpen, setIsLearnMoreModalOpen] =
    useState<boolean>(false)
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)

  const [uploadBuyListAssetsCsv, { isLoading }] =
    paveApi.useUploadBuyListAssetsCsvMutation()

  const validateData = () => {
    const newErrors: ErrorObject = {}

    if (!displayName) {
      newErrors.displayName = REQUIRED_FIELD
    }

    if (isEmpty(assets)) {
      newErrors.assets = BUY_LIST_REQUIRES_ONE_ASSET
    }

    setFormErrors(newErrors)
    return {
      isValidData: size(newErrors) === 0,
      errors: newErrors,
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    const { isValidData } = validateData()
    if (!isValidData) {
      showErrorToast('Please complete the form to continue')
      return
    }
    const buyListPayload = {
      displayName,
      assetSymbols: assets.map((asset) => asset.symbol),
    }
    submitBuyListForm(buyListPayload)
  }

  const handleFileUpload = (formData: { body: FormData }) => {
    uploadBuyListAssetsCsv(formData)
      .unwrap()
      .then((result: UpdateBuyListAssetsWithFileResponseDto) => {
        if (result.status === STATUS_ENUM.ERROR) throw result

        setAssets(result.assets)
        showSuccessToast('File uploaded successfully!')
      })
      .catch((e) => {
        if (e?.status === STATUS_ENUM.ERROR) {
          showErrorToast(e.errorMessage)
        } else {
          showErrorToast('Error uploading file.')
        }
      })
      .finally(() => {
        setIsUploadModalOpen(false)
      })
  }

  const MemoizedBuyListAssetsTable = useMemo(() => {
    return <BuyListAssetsTable assets={assets} />
  }, [assets])

  return (
    <>
      {showLoader && <ModalLoader />}
      <form id="buy-list-form" onSubmit={handleSubmit}>
        <Box marginBottom={SPACE.sp24}>
          <Box marginBottom={SPACE.sp8}>
            <Text
              fontWeight="medium"
              fontSize={FONT_SIZE.fs14}
              lineHeight="xs"
              color={COLOR.coolGray700}
            >
              Please enter the assets you would like Pave Pro to consider when
              constructing portfolios. Only portfolios using this Buy List will
              be constrained to use its assets.{' '}
              <Text
                color={COLOR.purple900}
                fontWeight="bold"
                style={{ cursor: 'pointer' }}
                onClick={() => setIsLearnMoreModalOpen(true)}
              >
                Learn More
              </Text>
            </Text>
          </Box>
        </Box>
        <Box marginBottom={SPACE.sp20}>
          <Box marginBottom={SPACE.sp8}>
            <Text
              color={COLOR.coolGray600}
              fontSize={FONT_SIZE.fs16}
              fontWeight={700}
            >
              General
            </Text>
          </Box>
          <TextInput
            disabled={!isNewBuyList}
            errorMessage={formErrors.displayName}
            label="Buy List Name"
            maxLength={DISPLAY_NAME_LENGTH_LIMIT}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDisplayName(e.target.value.trim())
            }
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDisplayName(e.target.value)
            }
            placeholder="Buy List Name"
            showRequiredStar={true}
            value={displayName}
          />
        </Box>
        <Card marginBottom={SPACE.sp20}>
          <FlexBox
            marginBottom={SPACE.sp8}
            gap={SPACE.sp24}
            alignContent="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center" gap={SPACE.sp4}>
              <Text
                color={COLOR.coolGray600}
                fontSize={FONT_SIZE.fs14}
                fontWeight={600}
              >
                Assets
              </Text>
              <BuyListAssetPopOver />
            </Box>
            <Button
              size="sm"
              variant="secondary"
              onClick={(e: React.SyntheticEvent) => {
                e.preventDefault()
                setIsUploadModalOpen(true)
              }}
            >
              <UploadOutlined />
              &nbsp;Upload New List
            </Button>
          </FlexBox>
          {formErrors.assets && (
            <Text
              color="status.error"
              fontWeight="medium"
              fontSize="xs"
              lineHeight="xxs"
              mt="sm"
              mb={0}
              as="p"
            >
              {formErrors.assets}
            </Text>
          )}
          {MemoizedBuyListAssetsTable}
        </Card>
      </form>
      <BuyListLearnMoreModal
        isOpen={isLearnMoreModalOpen}
        onClose={() => setIsLearnMoreModalOpen(false)}
      />
      <UploadBuyListAssetsModal
        isOpen={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
        handleFileUpload={handleFileUpload}
        isLoading={isLoading}
      />
    </>
  )
}
