import styled from '@emotion/styled'
import { analytics } from 'analytics'
import { orderBy } from 'lodash'
import { useEffect, useState } from 'react'

import { Asset, Portfolio, PortfolioLinkType } from 'types'

import { getPortfolioLinkType } from '../../utils/portfolioUtil'
import {
  Box,
  Button,
  Dropdown,
  SearchBar,
  Text,
  ViewToggle,
} from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import Pagination from 'components/common/Pagination/Pagination'
import CardSection from 'components/common/Section/CardSection'
import {
  AssetCard,
  AssetListItem,
  GridView,
  ListView,
} from 'components/partials'
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from 'constants/pagination.constants'
import { PORTFOLIO_EDIT_ASSETS_CLICKED } from 'constants/track.constants'
import { openEditPortfolioAssets } from 'store/modalsSlice'
import { useAppDispatch } from 'store/storeHooks'
import { SPACE } from 'styles/constants/space'
import { jsonClone } from 'utils/generalUtils'
import { searchFilter } from 'utils/searchFilter'

type Props = {
  portfolio: Portfolio
  disabled?: boolean
  freezeHoldings?: boolean
}

type AssetSortOption = 'total' | 'change' | 'ticker'
type DisplayView = 'grid' | 'list'

function AssetList({
  portfolio,
  freezeHoldings = false,
  disabled = false,
}: Props) {
  const dispatch = useAppDispatch()
  const [view, setView] = useState<DisplayView>('grid')
  const [search, setSearch] = useState('')
  const [sortField, setSortField] = useState<AssetSortOption>('total')

  const linkType = getPortfolioLinkType(portfolio)
  const isLinkedPortfolio = linkType !== PortfolioLinkType.None

  // Get all unsupported asset tickers where isSupported = true
  // TODO: Remove after all equities and MAC assets are changed to supportedType: ALL [ADV-438]
  const supportedAssetsNotSupportedByAssetClass = new Set(
    portfolio.unsupportedAssets
      .filter((asset) => asset.isSupported)
      .map((asset) => asset.symbol)
  )

  const [assets, setAssets] = useState<Asset[]>([])
  const [assetCount, setAssetCount] = useState(portfolio.assets.length)
  const [page, setPage] = useState(1)
  const [selectedItemsPerPageOption, setSelectedItemsPerPageOption] = useState(
    DEFAULT_ITEMS_PER_PAGE_OPTIONS[0]
  )

  const itemsPerPageOptions = DEFAULT_ITEMS_PER_PAGE_OPTIONS.concat({
    label: 'See All',
    value: assetCount,
  })

  const { isThirdPartyManaged } = portfolio || {}

  const handleEditAssets = () => {
    dispatch(openEditPortfolioAssets(portfolio))
    analytics.track(PORTFOLIO_EDIT_ASSETS_CLICKED)
  }

  if (freezeHoldings) disabled = true

  useEffect(() => {
    let newAssets = jsonClone(portfolio.assets) as Asset[]
    let newUnsupportedAssets = jsonClone(
      portfolio.unsupportedAssets ?? []
    ) as Asset[]

    function processAssets(assets: Asset[]): Asset[] {
      switch (sortField) {
        case 'total':
          assets = orderBy(
            assets,
            [(item) => (!item.totalValue ? -Infinity : item.totalValue)],
            'desc'
          )
          break
        case 'change':
          assets = orderBy(
            assets,
            [
              (item) =>
                !item?.priceDetails?.dayGain
                  ? -Infinity
                  : item.priceDetails.dayGain,
            ],
            'desc'
          )
          break
        case 'ticker':
          assets = orderBy(assets, 'symbol')
          break
      }

      const filteredAssets = assets.filter(
        (item) =>
          (searchFilter(item, 'name', search) ||
            searchFilter(item, 'symbol', search)) &&
          item?.totalValue > 0
      )

      return filteredAssets
    }

    newAssets = processAssets(newAssets)
    newUnsupportedAssets = processAssets(newUnsupportedAssets)

    const filteredAssets = [...newAssets, ...newUnsupportedAssets]

    const assetsToShow = filteredAssets.slice(
      (page - 1) * selectedItemsPerPageOption.value,
      page * selectedItemsPerPageOption.value
    )

    setAssets(assetsToShow)
    setAssetCount(filteredAssets.length)
  }, [
    selectedItemsPerPageOption,
    search,
    page,
    portfolio.assets,
    portfolio.unsupportedAssets,
    sortField,
  ])

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    setPage(1)
  }

  return (
    <CardSection>
      <>
        <Header>
          <div>
            <Text
              color="coolGray.600"
              fontSize="md"
              lineHeight="md"
              fontWeight="bold"
            >
              Assets
              <Text color="coolGray.600" fontSize="md" lineHeight="md">
                &nbsp;({assetCount})
              </Text>
            </Text>
          </div>
          {isThirdPartyManaged || isLinkedPortfolio || (
            <Button
              variant="tertiary"
              size="xs"
              onClick={handleEditAssets}
              disabled={disabled}
            >
              Edit Holdings
            </Button>
          )}
        </Header>
        <MenuBar>
          <Box display="flex" gap="24px">
            <ViewToggle view={view} onChange={setView} />
            <FlexBox>
              <Dropdown
                options={itemsPerPageOptions}
                onChange={(option: any) => {
                  setSelectedItemsPerPageOption(option)
                  setPage(1)
                }}
                value={selectedItemsPerPageOption}
                styles={{
                  control: (base: any) => ({
                    ...base,
                    borderRadius: '0.5rem',
                    height: '2.5rem',
                  }),
                }}
              />
            </FlexBox>
            <Box width="320px">
              <SearchBar
                placeholder="Search assets"
                value={search}
                onChange={handleSearch}
              />
            </Box>
          </Box>
          <Box paddingLeft={SPACE.sp6}>
            <Text
              fontSize="xs"
              lineHeight="xs"
              fontWeight="bold"
              color="coolGray.500"
              padding={`${SPACE.sp8} ${SPACE.sp6}`}
            >
              Sort by:
            </Text>
            <Button
              variant="tertiary"
              size="xs"
              active={sortField === 'total'}
              onClick={() => setSortField('total')}
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              Total Value
            </Button>
            <Button
              variant="tertiary"
              size="xs"
              active={sortField === 'change'}
              onClick={() => setSortField('change')}
            >
              % Change
            </Button>
            <Button
              variant="tertiary"
              size="xs"
              active={sortField === 'ticker'}
              onClick={() => setSortField('ticker')}
            >
              Ticker
            </Button>
          </Box>
        </MenuBar>
        {view === 'grid' && (
          <GridView
            marginTop={SPACE.sp24}
            gap={4}
            data={assets}
            renderItem={(item) => (
              <AssetCard
                key={item.id ?? item.symbol}
                asset={item}
                portfolioId={portfolio.id}
                isSupportedButNotByAssetClass={supportedAssetsNotSupportedByAssetClass.has(
                  item.symbol
                )}
              />
            )}
          />
        )}
        {view === 'list' && (
          <ListView
            data={assets}
            header={
              <FlexBox
                width="100%"
                p={`${SPACE.sp16} ${SPACE.sp20}`}
                fontSize="xxs"
                lineHeight="xxs"
                fontWeight="medium"
                color="coolGray.500"
                gap={SPACE.sp4}
              >
                <Box flex={1}>
                  <Text>SYMBOL</Text>
                </Box>
                <Box flex={2}>
                  <Text>NAME</Text>
                </Box>
                <Box flex={1}>
                  <Text>PRICE</Text>
                </Box>
                <Box flex={1}>
                  <Text>QUANTITY</Text>
                </Box>
                <Box flex={1}>
                  <Text>TOTAL VALUE</Text>
                </Box>
                <Box flex={1}>
                  <Text>% PORTFOLIO</Text>
                </Box>
                <Box flex={1}>
                  <Text>DAY GAIN %</Text>
                </Box>
                <Box flex={1}>
                  <Text>DAY GAIN VALUE</Text>
                </Box>
              </FlexBox>
            }
            renderItem={(item) => (
              <AssetListItem
                key={item.id}
                asset={item}
                portfolioId={portfolio.id}
                isSupportedButNotByAssetClass={supportedAssetsNotSupportedByAssetClass.has(
                  item.symbol
                )}
              />
            )}
          />
        )}
        <Box marginTop={SPACE.sp16}>
          <Pagination
            currentPage={page}
            handlePageChange={handlePageChange}
            itemsCount={assetCount}
            itemsPerPage={selectedItemsPerPageOption.value}
          />
        </Box>
      </>
    </CardSection>
  )
}

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
`

const MenuBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`

export default AssetList
