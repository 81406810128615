import { Dispatch, useEffect } from 'react'

import { Benchmark, InvestmentTemplate, Strategies } from 'types'
import { ReducerAction } from 'types'
import { InvestmentTemplateFormState } from 'types/InvestmentTemplateTypes'
import { PortfolioFormState } from 'types/portfolioTypes'

import { paveApi } from 'api'
import { Dropdown } from 'components/common'
import {
  setBenchmarkId,
  setStrategy,
} from 'components/reducers/portfolioFormReducer'
import { strategySelectorOptions } from 'constants/general.constants'
import { isTemplateField } from 'utils/portfolioFormUtil'

type Props = {
  dispatch: Dispatch<ReducerAction>
  formState: PortfolioFormState | InvestmentTemplateFormState
  formErrors?: {
    strategy?: string
  }
  isClearable?: boolean
  onClearValue?: () => void
  selectedInvestmentTemplate?: InvestmentTemplate | null
}

export default function MultiAssetClassStrategySelector({
  dispatch,
  formErrors,
  formState,
  isClearable = false,
  onClearValue,
  selectedInvestmentTemplate = null,
}: Props) {
  const { data: benchmarks } = paveApi.useGetBenchmarksQuery()
  const { multiAssetClassStrategy } = formState

  useEffect(() => {
    if (multiAssetClassStrategy) {
      const correspondingBenchmark: Benchmark | undefined = benchmarks?.find(
        ({ code }) => code === multiAssetClassStrategy
      )
      if (correspondingBenchmark) {
        dispatch(setBenchmarkId(correspondingBenchmark.id))
      }
    }
  }, [benchmarks, dispatch, multiAssetClassStrategy])

  return (
    <Dropdown
      errorMessage={formErrors?.strategy}
      label="Investment Strategy"
      key="strategy"
      isClearable={isClearable}
      isSearchable={false}
      onChange={(option: unknown) => {
        const { value } = option as { value: Strategies }
        dispatch(setStrategy(value))
      }}
      isDisabled={isTemplateField(selectedInvestmentTemplate, 'benchmarkId')}
      onClearValue={onClearValue}
      options={strategySelectorOptions.strategies}
      value={
        strategySelectorOptions.strategies.find(
          ({ value }) => value === multiAssetClassStrategy
        ) ?? null
      }
      variant={isClearable ? 'green' : 'base'}
    />
  )
}
