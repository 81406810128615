import { XIcon } from '@heroicons/react/solid'

import { Box, Dropdown, Tag, Text } from 'components/common'
import CustomValueContainer from 'components/common/Dropdown/CustomValueContainer'
import { DropdownVariant } from 'components/common/Dropdown/Dropdown'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

export type DropdownOptionString = {
  label: string
  value: string
}

export type DropdownOptionNumber = {
  label: string
  value: number
}

type Props = {
  isDisabled?: boolean
  options: DropdownOptionString[]
  label?: string
  placeholder?: string
  tagColor?: 'gray' | 'green' | 'red'
  onChange?: (values: any) => void
  customComponents?: {}
  value?: string[]
  variant?: DropdownVariant
}

function DropdownWithTags({
  isDisabled = false,
  options,
  value = [],
  label = '',
  placeholder = '',
  tagColor = 'gray',
  onChange,
  customComponents,
  variant = 'base',
}: Props) {
  const selectedOptions = options.filter((o) => value.includes(o.value))

  const handleChange = (values: unknown) => {
    onChange?.((values as DropdownOptionString[]).map((o) => o.value))
  }

  const handleRemove = (option: DropdownOptionString) => {
    const newVals = selectedOptions.filter((o) => o.value !== option.value)
    handleChange(newVals)
  }

  return (
    <Box>
      {label && (
        <Box marginBottom={SPACE.sp4}>
          <Text
            color={COLOR.coolGray600}
            fontSize={FONT_SIZE.fs14}
            fontWeight={500}
          >
            {label}
          </Text>
        </Box>
      )}
      <Dropdown
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          ValueContainer: CustomValueContainer,
          ...customComponents,
        }}
        isDisabled={isDisabled}
        options={options}
        value={selectedOptions}
        onChange={handleChange}
        placeholder={placeholder}
        styles={{
          control: (base: any) => ({
            ...base,
            height: '2.75rem',
          }),
          valueContainer: (base: any) => ({
            ...base,
            padding: 8,
          }),
          singleValue: (base: any) => ({
            ...base,
            marginLeft: 24,
          }),
          placeholder: (base: any) => ({
            ...base,
            marginLeft: 24,
          }),
          input: (base: any) => ({
            ...base,
            marginLeft: 24,
          }),
        }}
        isMulti
        controlShouldRenderValue={false}
        variant={variant}
      />

      {selectedOptions && selectedOptions.length > 0 && (
        <FlexBox
          width="100%"
          flexWrap="wrap"
          gap={SPACE.sp12}
          marginBottom={SPACE.sp12}
          marginTop={SPACE.sp12}
        >
          {selectedOptions.map((option) => (
            <Tag
              key={option.value}
              icon={!isDisabled && <XIcon />}
              iconPosition="right"
              variant={tagColor}
              onIconClick={() => handleRemove(option)}
            >
              {option.label as string}
            </Tag>
          ))}
        </FlexBox>
      )}
    </Box>
  )
}

export default DropdownWithTags
