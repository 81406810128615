import { analytics } from 'analytics'

import { AVATARS } from 'mockdata'
import { CreateClientAccountDto } from 'types/dto/ClientAccount.dto'

import { paveApi } from 'api'
import { Box, LinkText, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import {
  AccountList,
  AddAccountModal,
  IntroCard,
  PageLayout,
} from 'components/partials'
import MainAccountsCard from 'components/partials/Account/MainAccountsCard'
import HomeSuggestedTradesSection from 'components/partials/Home/HomeSuggestedTradesSection'
import { ActivityGroupProps } from 'components/partials/TradeActivities/ActivityGroup'
import {
  HOME_ADD_GROUP_FAILED,
  HOME_ADD_GROUP_SUBMITTED,
  HOME_ADD_GROUP_SUCCESS,
} from 'constants/track.constants'
import { useCustomToast } from 'hooks/useCustomToast'
import { closeAddAccount } from 'store/modalsSlice'
import { useAppDispatch, useAppSelector } from 'store/storeHooks'
import { SPACE } from 'styles/constants/space'

function HomePage() {
  const { showErrorToast, showSuccessToast } = useCustomToast()
  const { firstName, lastName, managementOrganizationId } = useAppSelector(
    (state: any) => state.user
  )
  const dispatch = useAppDispatch()
  const { data: clientAccounts, refetch } = paveApi.useGetClientAccountsQuery(
    {
      managementOrgId: managementOrganizationId,
    } || {},
    {
      skip: !managementOrganizationId,
      refetchOnMountOrArgChange: true,
    }
  )
  const { data: mangementOrganizationData } =
    paveApi.useGetManagementOrganzationQuery(managementOrganizationId, {
      skip: !managementOrganizationId,
      refetchOnMountOrArgChange: true,
    })
  const [createClientAccount] = paveApi.useCreateClientAccountMutation()

  const onCreateClientAccount = (clientAccount: CreateClientAccountDto) => {
    analytics.track(HOME_ADD_GROUP_SUBMITTED)
    createClientAccount(clientAccount)
      .unwrap()
      .then(() => {
        dispatch(closeAddAccount())
        showSuccessToast('New group created!')
        analytics.track(HOME_ADD_GROUP_SUCCESS)
        refetch()
      })
      .catch((e) => {
        const displayMessage = e?.data?.displayMessage
        if (displayMessage) {
          showErrorToast(displayMessage)
        } else {
          showErrorToast('An error occurred when creating a new group.')
        }

        analytics.track(HOME_ADD_GROUP_FAILED)
      })
  }

  const activityGroups: ActivityGroupProps[] = []
  clientAccounts?.forEach((c) => {
    if (!c.portfolios) {
      return
    }

    const suggestedTradesCount = c.portfolios.reduce(
      (acc, p) => acc + p.suggestedTrades.length,
      0
    )
    if (!suggestedTradesCount || !c.portfolios.length) {
      return
    }

    const activities = c.portfolios
      .filter((p) => p.suggestedTrades.length > 0)
      .map((p) => {
        return {
          id: p.id,
          leftText: `${p.displayName}`,
          rightText: `${p.suggestedTrades.length} trades`,
          link: `portfolio/${p.id}?entryPoint=home`,
        }
      })
    activityGroups.push({
      id: c.id,
      headerText: `${c.displayName}`,
      accountName: '',
      accountLogo: AVATARS[0],
      activities,
      count: suggestedTradesCount,
      link: '',
      open: true,
    })
  })

  return (
    <PageLayout>
      <Box padding={SPACE.sp32} maxWidth="83rem">
        <Text
          color="coolGray.800"
          fontWeight="700"
          fontSize="sm"
          lineHeight="sm"
          as="h1"
          m="0 0 8px 0"
        >
          Home Page
        </Text>
        <Text
          color="coolGray.800"
          fontWeight="700"
          fontSize="3xl"
          lineHeight="3xl"
          as="h2"
          m="0 0 24px 0"
        >
          Dashboard
        </Text>
        <Box>
          <FlexBox height="21rem" marginBottom={SPACE.sp24}>
            <Box marginRight={SPACE.sp24}>
              <IntroCard
                userName={`${firstName} ${lastName}`}
                introSummary={
                  <p>
                    Pave Pro is a cutting edge portfolio construction product
                    that will help you quickly and simply build highly
                    personalized and expertly risk managed portfolios. To get
                    started:
                    <br /> <br />
                    1. Create groups to organize your portfolios
                    <br />
                    2. Add portfolios and specify investment preferences
                    <br />
                    3. Receive and execute suggested trades
                    <br />
                    <br />
                    Please contact us at{' '}
                    <a href="mailto:hello@pavefinance.com">
                      <b>hello@pavefinance.com</b>
                    </a>{' '}
                    if you have any questions.
                  </p>
                }
              />
            </Box>
            <Box width="100%" minWidth="24rem">
              <HomeSuggestedTradesSection activities={activityGroups || []} />
            </Box>

            {mangementOrganizationData?.mainAccountsInfo && (
              <Box marginLeft={SPACE.sp24}>
                <MainAccountsCard
                  mainAccountsInfo={mangementOrganizationData.mainAccountsInfo}
                />
              </Box>
            )}
          </FlexBox>
          <Box>
            <AccountList accounts={clientAccounts || []} />
          </Box>
        </Box>
        <AddAccountModal onCreateClientAccount={onCreateClientAccount} />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        mt="sm"
        mb="lg"
      >
        <Text color="gray.500" fontSize="sm">
          Pave Securities, LLC Is a member of{' '}
          <LinkText
            href="https://www.finra.org/#/"
            label="FINRA"
            openInNewTab
          />{' '}
          and{' '}
          <LinkText href="https://www.sipc.org/" label="SIPC" openInNewTab />{' '}
          and regulated by the US Securities and Exchange Commission
        </Text>
      </Box>
    </PageLayout>
  )
}

export default HomePage
