import { analytics } from 'analytics'
import { useState } from 'react'

import { CreateClientAccountDto } from 'types/dto/ClientAccount.dto'

import { paveApi } from 'api'
import { Box, Text } from 'components/common'
import BreadcrumbBar from 'components/common/BreadcrumbBar/BreadcrumbBar'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { AddAccountModal, PageLayout } from 'components/partials'
import ConnectedAccountsGroupCardSection from 'components/partials/ConnectedAccounts/ConnectedAccountsGroupCardSection'
import ManagementOrganizationAccountsCardSection from 'components/partials/Settings/ManagementOrganizationAccountsCardSection'
import SchwabAccountGroupAssignmentModal from 'components/partials/Settings/SchwabAccountGroupAssignmentModal'
import {
  CONNECTED_ACCOUNTS_ADD_GROUP_FAILED,
  CONNECTED_ACCOUNTS_ADD_GROUP_SUBMITTED,
  CONNECTED_ACCOUNTS_ADD_GROUP_SUCCESS,
  LINK_SUB_ACCOUNT_TO_GROUP_MODAL_OPENED,
} from 'constants/track.constants'
import { useCustomToast } from 'hooks/useCustomToast'
import { closeAddAccount } from 'store/modalsSlice'
import { useAppDispatch, useAppSelector } from 'store/storeHooks'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

function ConnectedAccountsPage() {
  const { showErrorToast, showSuccessToast } = useCustomToast()
  const dispatch = useAppDispatch()
  const { managementOrganizationId } = useAppSelector(
    (state: any) => state.user
  )

  const { data: clientAccounts, refetch: refetchClientAccounts } =
    paveApi.useGetClientAccountsSummaryQuery(
      {
        managementOrgId: managementOrganizationId,
      } || {},
      {
        skip: !managementOrganizationId,
        refetchOnMountOrArgChange: true,
      }
    )

  const [createClientAccount] = paveApi.useCreateClientAccountMutation()
  const onCreateClientAccount = async (
    clientAccount: CreateClientAccountDto
  ) => {
    analytics.track(CONNECTED_ACCOUNTS_ADD_GROUP_SUBMITTED)
    await createClientAccount(clientAccount)
      .unwrap()
      .then(() => {
        showSuccessToast('New group created!')
        dispatch(closeAddAccount())
        analytics.track(CONNECTED_ACCOUNTS_ADD_GROUP_SUCCESS)
      })
      .catch(() => {
        showErrorToast('An error occurred when creating a new group.')
        analytics.track(CONNECTED_ACCOUNTS_ADD_GROUP_FAILED)
      })
    refetchClientAccounts()
  }

  const {
    data: managementOrganizationAccounts,
    isFetching,
    refetch: refetchLinkedAccounts,
  } = paveApi.useGetLinkedAccountsQuery(managementOrganizationId || '', {
    skip: !managementOrganizationId,
  })

  const [selectedSubAccountNumbers, setSelectedSubAccountNumbers] = useState<
    string[]
  >([])
  const [selectedAccountNames, setSelectedAccountNames] = useState<string[]>([])
  const [isAddToGroupModalOpen, setIsAddToGroupModalOpen] = useState(false)

  const handleAssignToGroup = (subAccountNumbers: string[]) => {
    setSelectedSubAccountNumbers(subAccountNumbers)
    setSelectedAccountNames(
      subAccountNumbers.map((subAccountNumber) => {
        const selectedAccount = managementOrganizationAccounts?.find(
          (account) => account.subAccountNumber === subAccountNumber
        )
        return selectedAccount?.title || ''
      })
    )
    analytics.track(LINK_SUB_ACCOUNT_TO_GROUP_MODAL_OPENED)
    setIsAddToGroupModalOpen(true)
  }

  const breadcrumbs = [
    {
      name: 'Home Page',
      route: `/`,
    },
    {
      name: 'Connected Accounts',
      route: `#`,
    },
  ]
  return (
    <PageLayout>
      <BreadcrumbBar paths={breadcrumbs} />
      <Box marginLeft={SPACE.sp32}>
        <Text
          color={COLOR.coolGray600}
          fontWeight="700"
          fontSize={FONT_SIZE.fs14}
          lineHeight="sm"
          as="h1"
        >
          Connected Accounts
        </Text>
        <Text
          color={COLOR.coolGray600}
          fontWeight="700"
          fontSize={FONT_SIZE.fs28}
          lineHeight="3xl"
          as="h2"
        >
          Manage Groups
        </Text>
      </Box>
      <FlexBox flexDirection="row" gap={SPACE.sp24} padding={SPACE.sp32}>
        <Box flex={1}>
          <ConnectedAccountsGroupCardSection accounts={clientAccounts || []} />
        </Box>
        <Box flex={4}>
          <ManagementOrganizationAccountsCardSection
            managementOrganizationAccounts={managementOrganizationAccounts}
            clientAccounts={clientAccounts}
            isFetching={isFetching}
            handleAssignToGroup={handleAssignToGroup}
          />
        </Box>
      </FlexBox>
      <AddAccountModal onCreateClientAccount={onCreateClientAccount} />
      <SchwabAccountGroupAssignmentModal
        isOpen={isAddToGroupModalOpen}
        onClose={() => setIsAddToGroupModalOpen(false)}
        accountIds={selectedSubAccountNumbers}
        accountNames={selectedAccountNames}
        clientAccounts={clientAccounts || []}
        refetch={() => {
          refetchClientAccounts()
          refetchLinkedAccounts()
        }}
      />
    </PageLayout>
  )
}

export default ConnectedAccountsPage
