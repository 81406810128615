import { CSSProperties, HTMLAttributes } from 'react'

import { Box } from 'components/common'
import { COLOR } from 'styles/constants/color'
import { SPACE } from 'styles/constants/space'

type Props = HTMLAttributes<HTMLDivElement> & {
  children: React.ReactElement
  style?: CSSProperties
}

export default function CardSection({
  children,
  style,
}: Props): React.ReactElement {
  return (
    <Box
      padding={SPACE.sp24}
      backgroundColor={COLOR.white}
      border={`1px solid ${COLOR.coolGray300}`}
      borderRadius={SPACE.sp8}
      style={style}
    >
      {children}
    </Box>
  )
}
